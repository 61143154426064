import LoriaLogo from "../assets/logos/logo-escuela-infantil-loria.png"

export const TitlePage = () => {

  return (
    <div className="my-5">
      <div className="flex items-end justify-between">
        <a className="flex items-center" href="index.html">
          <img src={LoriaLogo} alt="Logo Escuela Infantil Loria"
            className="w-24 h-24 rounded-full shadow" />
          <h1 className="text-white text-center ml-5">
            <span className="text-lg leading-none">Escuela Infantil</span>
            <br />
            <span className="text-6xl leading-none">Loria</span>
          </h1>
        </a>
      </div>
    </div>
  )
}