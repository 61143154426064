import './App.css';
import { Main } from './components/Main';
import { Menu } from './components/Menu';
import { MobileMenu } from './components/MobileMenu';
import { TitlePage } from './components/TitlePage';
import { Whatsapp } from './components/Whatsapp';

function App() {
  return (
    <div className="App">
      <div className='flex justify-center'>
        <div className='w-full sm:w-[70%] max-w-full sm:max-w-[1024px]'>
          <div className='sm:mx-5'>
            <div className='sm:flex flex justify-between items-end mx-10 hidden'>
              <TitlePage />
              <Menu />
            </div>
            <div className='block sm:hidden'>
              <div className='bg-white'>
                <MobileMenu />
              </div>
            </div>
          </div>
          <Main />
        </div>
        <Whatsapp />
      </div>
    </div>
  );
}

export default App;
