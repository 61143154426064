import React, { useState } from 'react';
const images = [
  require('../assets/images/carousel/1.jpg'),
  require('../assets/images/carousel/2.jpeg'),
  require('../assets/images/carousel/3.jpg'),
  require('../assets/images/carousel/4.jpg'),
  require('../assets/images/carousel/5.jpg'),
  require('../assets/images/carousel/6.jpg'),
  require('../assets/images/carousel/7.jpg'),
  require('../assets/images/carousel/8.jpg'),
  require('../assets/images/carousel/10.jpg'),
];

const Carousel = () => {
  const [positions, setPositions] = useState([0, 1, 2, -2, -1]);

  const updatePositions = (newActivePos) => {
    const newPositions = positions.map((pos) =>
      getPos(pos, newActivePos)
    );
    setPositions(newPositions);
  };

  const handleClick = (index) => {
    if (positions[index] === 0) return;
    updatePositions(positions[index]);
  };

  const getPos = (current, active) => {
    const diff = current - active;
    if (Math.abs(diff) > 2) return -current;
    return diff;
  };

  return (
    <div className="flex w-full h-full items-center font-sans">
      <ul className="relative w-full h-[300px] flex justify-center perspective-[300px]">
        {images.map((image, index) => (
          <li
            key={index}
            data-pos={positions[index]}
            className={`absolute flex items-center justify-center text-white 
                        w-[125px] sm:w-[250px] h-[200px] sm:h-[400px] rounded-lg 
                        shadow-md transition-all ease-in duration-300
                        cursor-pointer
                        ${positions[index] === 0 ? 'z-[5]' : ''}
                        ${positions[index] === -1 || positions[index] === 1 ? 'z-[4] opacity-70 filter blur-sm grayscale-[10%] scale-[0.9]' : ''}
                        ${positions[index] === -2 || positions[index] === 2 ? 'z-[3] opacity-40 filter blur-[3px] grayscale-[20%] scale-[0.8]' : ''}
                        ${positions[index] === -1 ? 'translate-x-[-40%]' : ''}
                        ${positions[index] === 1 ? 'translate-x-[40%]' : ''}
                        ${positions[index] === -2 ? 'translate-x-[-70%]' : ''}
                        ${positions[index] === 2 ? 'translate-x-[70%]' : ''}
                        ${positions[index] === undefined ? 'hidden' : ''}`}
            onClick={() => handleClick(index)}
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover rounded-lg"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Carousel;
