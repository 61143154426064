import React, { useState } from 'react';


const images = [
  require('../assets/images/institucion/1.jpg'),
  require('../assets/images/institucion/2.jpeg'),
  require('../assets/images/institucion/3.jpeg'),
  require('../assets/images/institucion/4.jpeg'),
  require('../assets/images/institucion/5.jpeg'),
  require('../assets/images/institucion/6.jpeg'),
  require('../assets/images/institucion/7.jpeg'),
  require('../assets/images/institucion/8.jpeg'),
  require('../assets/images/institucion/9.jpeg'),
  require('../assets/images/institucion/10.jpeg'),
  require('../assets/images/institucion/11.jpeg'),
  require('../assets/images/institucion/12.jpeg'),
  require('../assets/images/institucion/13.jpeg'),
];

const CarouselEstablishment = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className=''>
      <div className="relative sm:w-[27rem] h-auto mx-auto shadow-lg rounded-lg">
        <div className="overflow-hidden relative rounded-lg h-[15rem] sm:h-[19rem] w-auto border">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`absolute top-0 left-0 sm:w-fit sm:h-fit object-cover transition-transform transform ${index === currentIndex ? 'translate-x-0' : 'translate-x-full'
                }`}
              style={{
                transform: `translateX(${100 * (index - currentIndex)}%)`,
              }}
            />
          ))}
        </div>
        <button
          onClick={prevSlide}
          className="absolute top-1/2 transform -translate-y-1/2 left-[-1rem] sm:left-[-3.5rem] bg-white hover:bg-blank transition text-primary py-2 px-4 rounded-full"
          style={{boxShadow: "rgba(0, 0, 0, 0.75) 0px 1px 3px 0px"}}
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 transform -translate-y-1/2 right-[-1rem] sm:right-[-3.5rem] bg-white hover:bg-blank transition text-primary py-2 px-4 rounded-full"
          style={{boxShadow: "rgba(0, 0, 0, 0.75) 0px 1px 3px 0px"}}
        >
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default CarouselEstablishment;