import { About } from "./About"
import { Contact } from "./Contact"
import { Content } from "./Content"
import { Divider } from "./Divider"

export const Main = () => {

  return (
    <div className="rounded mb-10">
      <div className="sm:mx-5">
        <div className="mx-5 sm:mx-10 pb-10">
          <Content />
        </div>
        <Divider />
        <div className="mx-5 sm:mx-10 bg-white rounded-lg" id="about-us">
          <About />
        </div>
        <Divider />
        <div className="mx-5 sm:mx-10 bg-texting rounded-lg pb-10" id="contact">
          <Contact />
        </div>

      </div>
    </div>
  )
}