import React, { useState } from 'react';
import LoriaLogo from "../assets/logos/logo-escuela-infantil-loria.png"

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative z-50">
      {/* Botón del menú (hamburger icon) */}
      <div className="flex justify-between p-3">
        <div className='flex items-center'>
          <img src={LoriaLogo} alt="Logo Escuela Infantil Loria"
            className="w-14 h-14 ml-3 rounded-full shadow border border-primary" />
        </div>
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <svg
            className="h-8 w-8 text-gray-800"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>

      {/* Menú mobile */}
      {isOpen && (
        <div className="absolute top-[80px] left-0 w-full bg-white text-white h-auto border border-t-gray-200">
          <nav className="flex flex-col my-5 text-center text-primary">
            <a href="/" className="text-lg mb-2">
              Inicio
            </a>
            <a href="#about-us" className="text-lg my-2">
              Acerca de
            </a>
            <a href="#contact" className="text-lg my-2">
              Contacto
            </a>
            <div className='flex justify-center'>
              <button
                  className="text-texting transition bg-secondary mt-2 shadow px-3.5 py-2.5 rounded-full hover:bg-secondaryShine">
                  <a href="https://api.whatsapp.com/send?phone=541140668838" target="_blank"
                  >Inscripción</a>
              </button>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};
