import Carousel from "./Carousel"

export const Content = () => {

  return (
    <div className="pb-14 rounded">
      <div className="h-96 flex flex-col sm:flex-row items-center pt-14">
        <div className="sm:w-[40%] sm:z-50">
          <h1 className="text-3xl text-white font-bold">Escuela Infantil Loria</h1>
          <p className="sm:text-6xl text-2xl text-white mt-1 sm:mt-0 font-semibold">Inscripciones</p>
          <p className="sm:text-3xl text-xl text-white mt-1 sm:mt-4">Abiertas 2025</p>
          <button className="transition bg-secondary text-texting shadow px-5 py-2.5 mt-4 mb-6 sm:mb-0 rounded-full hover:bg-secondaryShine">
            <a href="https://api.whatsapp.com/send?phone=541140668838" target="_blank"
            >Consultanos</a>
          </button>
        </div>
        <div className="sm:w-[60%]">
          <Carousel />
        </div>
      </div>
    </div>
  )
}