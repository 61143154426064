import WhatsappLogo from "../assets/icons/whatsapp-violet.svg"
import Instagram from "../assets/icons/instagram.svg"
import Facebook from "../assets/icons/facebook.svg"
import Phone from "../assets/icons/phone.svg"
import Location from "../assets/icons/location-pin.svg"

export const Contact = () => {

  return (
    <div className="p-6">
      <div className="w-full flex flex-col text-start">
        <h2 className="text-xl text-white font-semibold">Contacto</h2>
      </div>
      <div className="flex flex-col sm:flex-row mt-10 items-center">
        <div className="sm:w-[50%] flex flex-col items-center">
          <div className="h-[300px] flex flex-col text-white justify-between">
            <a className="flex items-center" href="" target="_blank">
              <img src={WhatsappLogo} className="w-8 mr-2" />
              <p className="text-sm font-semibold">Whatsapp: <span className="font-normal hover:text-secondary border-b hover:border-secondary transition">+54 9 11 4066-8838</span></p>
            </a>
            <a className="flex items-center" href="https://www.instagram.com/jardinloria" target="_blank">
              <img src={Instagram} className="w-8 mr-2" />
              <p className="text-sm font-semibold">Instagram: <span className="font-normal hover:text-secondary border-b hover:border-secondary transition">@jardinloria</span></p>
            </a>
            <a className="flex items-center" href="https://www.facebook.com/escuelainfantilloria" target="_blank">
              <img src={Facebook} className="w-8 mr-2" />
              <p className="text-sm font-semibold">Facebook: <span className="font-normal hover:text-secondary border-b hover:border-secondary transition">escuelainfantilloria</span></p>
            </a>
            <div className="flex items-center">
              <img src={Phone} className="w-8 mr-2" />
              <p className="text-sm font-semibold">Teléfono: <span className="font-normal">4957-0351</span></p>
            </div>
            <div className="flex items-center">
              <img src={Location} className="w-8 mr-2" />
              <div className="flex justify-start">
                <p className="text-sm font-semibold">Dirección: <span className="font-normal">Sánchez de Loria 238, CABA, Argentina.</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-[50%] mt-12 flex justify-center">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.6512705248756!2d-58.413122599999994!3d-34.6129788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaf6ea13440f%3A0xa5ebc909f136e318!2sS%C3%A1nchez%20de%20Loria%20238%2C%20C1173ACF%20ACF%2C%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1724191059182!5m2!1ses!2sar" width="300" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}