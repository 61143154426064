import CarouselEstablishment from "./CarouselEstablishment"
import Informe from '../files/INFORMACION-ESCUELA-INFANTIL-LORIA.pdf'

export const About = () => {

  const handleDownload = () => {
    const fileUrl = Informe;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'Información Escuela Infantil Loria.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <div className="p-6">
      <div className="w-full flex flex-col text-start">
        <h2 className="text-xl text-texting font-semibold">Nosotros</h2>
        <p className="text-texting font-semibold mt-5">Nuestro proyecto institucional:</p>
        <p className="text-texting mt-2">
          Nuestra función como Institución es brindar amplias oportunidades de socialización, aprendizajes y conocimientos, reproducir normas, fomentar valores y desarrollar todas las potencialidades del niño mediante la libre expresión en todas las áreas, manteniendo una actividad vigente, participativa y creadora para que los niños logren comprender e interpretar el mundo en que viven para adaptarse, actuar, expresarse , modificarlo y /o manifestar sus necesidades o inquietudes sobre el entorno natural, cultural y social.
          De esta manera formamos sujetos libres, críticos, creativos, responsables, cooperativos, solidarios, comunicativos, autónomos e independientes, ofreciéndoles todas las herramientas pedagógicas para guiarlos en esta primer etapa de su vida escolar respetando sus tiempos y su desarrollo.
        </p>
        <div className="flex mt-10">
          <div className="w-full">
            <CarouselEstablishment />
          </div>
        </div>
        <p className="text-texting font-semibold mt-5">Plantel Docente:</p>
        <p className="text-texting mt-2">
          Formamos un equipo de docentes  especializados en el nivel inicial, capacitados en primera infancia teniendo en cuenta y priorizando las necesidades de cada alumno en forma particular, respetando sus tiempos y acompañándolos en el proceso de  aprendizaje con vocación , respeto y responsabilidad.
        </p>
        <div className="mx-5">
          <p className="text-texting font-semibold mt-5">Capacitadas en:</p>
          <div className="mx-5">
            <ul className="text-texting mt-2">
              <li>
                -Primeros auxilios y RCP.
              </li>
              <li>
                -Técnicas y modos de evacuación.
              </li>
              <li>
                -Manipulación de alimentos.
              </li>
              <li>
                -Inclusión educativa.
              </li>
              <li>
                -La prevención de salud al cuidado infantil.
              </li>
              <li>
                -La educación como factor de cambio hacia una cultura vial responsable.
              </li>
              <li>
                -Prevención de enfermedades transmitidas por AEDES - AEGYPTI.
              </li>
              <li>
                -Normas básicas de higiene y seguridad.
              </li>
              <li>
                -Pedagogía y seguimiento evolutivo del niño y sus necesidades.
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <p className="text-texting font-semibold mt-5">
            Información
          </p>
          <p className="text-texting mt-2">
            Te invitamos a descargar el infrome digital de la institución para obtener mas información sobre nuestra propuesta educativa y modalidad de trabajo.
          </p>
          <div className="flex justify-center mt-5">
            <button
              className="text-texting transition bg-secondary shadow px-3.5 py-2.5 rounded-full hover:bg-secondaryShine"
              onClick={handleDownload}
              >
              Descargar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}