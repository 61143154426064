import WhatsappLogo from "../assets/icons/whatsapp.svg"
import { useState, useEffect } from "react";

export const Whatsapp = () => {
    const [timeWhatsapp, setTimeWhatsapp] = useState();
    useEffect(() => {
        const horaActual = new Date();
        const hora = horaActual.getHours();
        const minutos = horaActual.getMinutes();
        const segundos = horaActual.getSeconds();
        const horaFormateada = `${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
        setTimeWhatsapp(horaFormateada)
    }, [])

    return (
        <div className="fixed bottom-6 right-6 sm:bottom-10 sm:right-10 z-20">
            <div>
                <div className="flex mb-5 hidden sm:flex">
                    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1"
                        x="0px" y="0px" enable-background="new 0 0 8 13">
                        <path opacity="0.13" fill="#fff"
                            d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path>
                        <path fill="#f8fafc" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z">
                        </path>
                    </svg>
                    <a href="https://api.whatsapp.com/send?phone=541140668838" target="_blank">
                        <div className="bg-slate-50 rounded-tr-lg rounded-br-lg rounded-bl-lg px-3 pt-2">
                            <p className="text-emerald-400 text-sm text-start font-bold">Escuela Infantil Loria</p>
                            <div className="flex items-end">
                                <p className="w-52 text-[#111B21] text-sm text-start font-medium leading-tight mb-2">Escribinos por Whatsapp para tener
                                    información más personalizada sobre la institución y la disponibilidad de vacantes</p>
                                <p className="text-[#667781] text-xs mb-1">{timeWhatsapp}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="w-full flex justify-center">
                    <a href="https://api.whatsapp.com/send?phone=541140668838" target="_blank">
                        <div className="flex justify-center items-center bg-emerald-400 p-4 w-24 h-24 rounded-full sm:ml-2">
                            <img src={WhatsappLogo} alt="Whatsapp Logo" className="w-14 h-14" />
                        </div>
                    </a>
                </div>
            </div>
        </div>

    )
}