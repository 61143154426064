export const Menu = () => {

  return (
    <div>
      <nav className="text-primary py-4">
        <div className="container flex justify-end items-center pr-0">
          <ul className="flex space-x-4 items-center">
            <li><a href="/" className="py-1 text-blank hover:border-b hover:border-primary transition">Inicio</a></li>
            <li><a href="#about-us" className="py-1 text-blank hover:border-b hover:border-primary transition">Acerca de</a></li>
            {/* <li><a href="#" className="py-1 hover:border-b hover:border-primary transition">Servicios</a></li> */}
            <li><a href="#contact" className="py-1 text-blank hover:border-b hover:border-primary transition">Contacto</a></li>
            <li>
              <button
                className="text-texting transition bg-secondary shadow px-3.5 py-2.5 rounded-full hover:bg-secondaryShine">
                <a href="https://api.whatsapp.com/send?phone=541140668838" target="_blank"
                >Inscripción</a>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}